<template>
	<div class="staff">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>ASSIST's CAR</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item">TUNED CAR</li>
								<li class="breadcrumb-item active" aria-current="page">
									ASSIST's CAR
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<div class="block section-padding-50">
			<div class="container">
				<div class="row mb-5" v-if="data != null">
					<template v-for="(item, key) in data.contents">
						<div class="col-12 mb-4" :key="key">
							<div class="row cars">
								<div class="col-12 col-md-6">
									<a :href="'/tuned/' + item.id">
										<img
											:src="item.main_image.url"
											v-if="item.main_image != undefined"
										/>
										<img
											src="/img/common/no_image.jpg"
											class="img-fluid"
											v-else
										/>
									</a>
								</div>
								<div class="text col-12 col-sm-6">
									<span
										class="democar badge badge-dark"
										v-if="item.democar == true"
										>ASSIST's CAR</span
									>
									<h3 class="name mt-2">
										<i class="fas fa-angle-double-right mr-2"></i
										>{{ item.name }}
									</h3>
									<div v-html="item.specification" class="specification"></div>
									<a :href="'/tuned/' + item.id" class="btn contact-btn mt-3"
										>詳細ページはこちら</a
									>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	title: "TUNED CAR",
	components: {
		//Sidebar,
	},
	data() {
		return {
			isLoading: false,
			data: null,
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			// microcms
			let endpoint =
				"https://assist-concept-cars.microcms.io/api/v1/cars?offset=0&orders=-publishedAt&filters=democar[equals]true";
			axios
				.get(endpoint, {
					headers: {
						"X-MICROCMS-API-KEY": "Zcnvkvp6KxnPWkf2WF9Uu7inXkkClTsv1YYr",
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.data = res.data;
					// simulate AJAX
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- 読み込んだHTMLテキスト向け -->
<style lang="scss">
.cars {
	.specification p {
		margin: 0 0 1rem 0;
		color: #fff;
		padding: 0 0.5rem;
	}
}
#spec {
	.category_text {
		margin-bottom: 2rem;
		padding: 0 0.6rem;
	}
	.list {
		h3 {
			margin-bottom: 0.4rem;
			padding: 0.6rem;
			color: #152535;
			font-size: 1rem;
			border-left: none;
			border-bottom: 2px solid #1976d2;
		}
		p {
			margin: 0 0 2rem 0.6rem;
		}
	}
}
</style>
<style scoped lang="scss">
.cars {
	background-color: #1976d2;
	padding: 2rem;
	.democar {
		font-size: 0.8rem;
		background-color: transparent;
	}
	.name {
		font-size: 1.6rem;
		color: #fff;
		padding: 0 0 1rem 0;
		border-left: none;
		border-bottom: 1px solid #fff;
	}
	img {
		margin-bottom: 1rem;
	}
	i {
		color: #ddd;
	}
	p {
		color: #fff;
	}
	.contact-btn {
		color: #1976d2 !important;
		background: #fff;
	}
	.contact-btn.active,
	.contact-btn:hover,
	.contact-btn:focus {
		color: #999 !important;
	}
}
</style>
